import BasePage from '@/pages/Base'
import Component from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import debounce from 'lodash/debounce'
import isEmpty from 'lodash/isEmpty'
import forEach from 'lodash/forEach'
import last from 'lodash/last'
import Viewer from 'v-viewer/src/component.vue'
import map from 'lodash/map'
import moment from 'moment'

import SettingsService from '@/services/GlobalSettings'
import Users from '@/services/UserSettings'
import Product from '@/services/Products'
import CreateOrder from '@/services/CreateOrder'
import ImportWizardService from '@/services/ImportWizard'
import CountryService from '@/services/Country'
import filter from 'lodash/filter'
import { upperCase, findLastIndex, unionWith } from 'lodash'

// for check product country_id when product add to cart
// const opts: any = {
//     params: {
//         'page[num]': 1,
//         'page[limit]': 20,
//         sort: '-created_at',
//     }
// }
// const response = await ImportWizardService.getSourceList(opts)

// import ItemsDetail from '@/containers/CreateOrderItems/CreateOrderItems.vue'
// import { Handler } from 'leaflet';

@Component({ components: { Viewer } })
export default class CreateOrderNonCatalog extends BasePage {
  constructor() {
    super()
  }

	searchUserDebounceMethod: Function
	searchAgentDebounceMethod: Function
	searchContactDebounceMethod: Function

	usingAgent: boolean = false
	// agentIsUse: boolean = false

	globalUserId: any = null
	arrItemIdForDelAll: any = []
	countrySourcesIdSelected: any = null
	usersSelectedFromSearch: any = null
	cart_id: any = null
	users: any = {
	  country_catalogs_keyword: null,
	  country_catalogs: [],
	  country_catalogs_selected: [],
	  current_country_catalogs_selected: [],
	  data: [],
	  selected: [],
	  cart: [],
	  selectedContact: {},
	  keyword: null,
	  loading: false,
	  order_for_email: '',
	  shipping_to_country: '',
	  order_currency: ''
	}

	// Info for User
	infos_user: any = {
	  first_name: null,
	  last_name: null,
	  company: null,
	  address_1: null,
	  address_2: null,
	  city: null,
	  city_id: null,
	  state: null,
	  postcode: null,
	  country: null,
	  phone: null,
	  total_cbm: null,
	  total_weight: null,
	  email: null
	}

	// Agent
	agentsSelectedFromSearch: any = null
	agents: any = {
	  country_catalogs_keyword: null,
	  country_catalogs: [],
	  country_catalogs_selected: [],
	  current_country_catalogs_selected: [],
	  cart: [],
	  selectedContact: {},
	  data: [],
	  selected: [],
	  loading: false,
	  is_agent_value: false,
	  keyword: null
	}
	globalAgentId: any = null

	contacts: any = {
	  data: [],
	  // selected: [],
	  selected: {
	    first_name: null,
	    last_name: null,
	    company: null,
	    address: null,
	    subdistrict: null,
	    city_name: null,
	    city_id: null,
	    province: null,
	    postcode: null,
	    country: null,
	    phone: null
	  },
	  loading: false,
	  keyword: null
	}
	calculationResult: any = {}
	details: any = {}
	products: any = {
	  headers: [
	    {
	      text: 'Actions',
	      align: 'center',
	      sortable: false,
	      value: 'images',
	      class: 'action-table-width'
	    },
	    {
	      text: 'Image',
	      align: 'left',
	      sortable: false,
	      value: 'productName'
	    },
	    {
	      text: 'Product Name',
	      align: 'left',
	      sortable: false,
	      value: 'productName'
	    },
	    {
	      text: 'Original Name',
	      align: 'left',
	      sortable: false,
	      value: 'originalName'
	    },
	    {
	      text: 'Quantity',
	      align: 'center',
	      sortable: false,
	      value: 'quantity'
	    },
	    {
	      text: 'Subtotal Product Cost',
	      align: 'left',
	      sortable: false,
	      value: 'totalProductCost'
	    },
	    {
	      text: 'Subtotal Logistic Cost',
	      align: 'center',
	      sortable: false,
	      value: 'totalLogistictCost'
	    },
	    {
	      text: 'Subtotal Landed Cost',
	      align: 'center',
	      sortable: false,
	      value: 'totalLandedCost'
	    }
	  ],
	  data: [],
	  loading: false
	}
	variants: any = {
	  open: false,
	  loading: false,
	  headers: []
	}
	addProduct: any = {
	  data: [],
	  selected: null,
	  keyword: {
	    data: null,
	    action: true
	  },
	  loading: false,
	  open: false
	}
	variations: any = {
	  getData: [],
	  variants: [],
	  headers: [],
	  loading: false
	}
	dialogDeleteItem: any = {
	  data: null,
	  open: false
	}
	dialogDeleteAllItem: any = {
	  data: null,
	  open: false
	}

	// sini
	payload_calculation = {
	  type: 'order',
	  logistic_per_unit: 150000,
	  weight_measurement: 'kg',
	  ship_from: 44,
	  ship_to: 99,
	  shipping_method: 'air',
	  currency_order: 'IDR',
	  currency_origin: 'CNY',
	  // conversion_rate: '',
	  insurance_value: 0,
	  order_type: 'trading',
	  freight_value: 0,
	  products: [],
	  custom_logistic_charges: [
	    // {
	    // 	type: 'custom-optional',
	    // 	group: 'Freight Charge',
	    // 	name: 'FC1',
	    // 	value: '',
	    // 	price: '0',
	    // 	vat: '10'
	    // }
	    {
	      type: 'custom-optional',
	      group: '[Estimate] China Domestic Shipping',
	      name: '[Estimate] China Domestic Shipping',
	      value: 0,
	      price: 0,
	      vat: 0
	    }
	  ]
	}

	selectedBulkPrice: any = []

	// Order Date
	orderDateDialogOpen: boolean = false
	orderDate: any = {}

	// Currency Origin
	currencyOriginProduct: string = ''

	setSourcesIdSelected(country_sources_id: any) {
	  // console.log('XXXXXXXX> pilihan',country_sources_id)
	  // console.log('XXXXXXXX nganu>',this.users.country_catalogs_selected)
	  this.countrySourcesIdSelected = country_sources_id

	  // replace this when it finished to handle

	  // console.log('this.users.selected.cart',this.users.selected.cart)

	  // if (this.users.selected.cart.lengthItem > 0) {
	  if (this.products.data.length > 0) {
	    // code...
	    var ship_from = null
	    if (isEmpty(this.agents.selected)) {
	      ship_from = Number(this.users.selected.cart.ship_from)
	    } else {
	      ship_from = Number(this.agents.selected.cart.ship_from)
	    }
	    if (country_sources_id !== ship_from) {
	      // code...
	      this.openDialogDeleteAllItem()
	      // this.deleteAllItem()
	    }
	  }

	  // jika country_sources_id tidak sama dengan ship_from di dalam cart maka
	  // lakukan prosedur penghapusan isi cart
	}

	deleteAllItem(prop) {
	  // console.log('delete item',prop)
	  // console.log('this.users.selected.cart',this.users.selected.cart.items.length)
	  // console.log('this.products.data.length',this.products.data.length)
	  var hasItem = false
	  if (Number(this.products.data.length) > 0) {
	    hasItem = true
	  }
	  // console.log('hasItem',hasItem)
	  // if (this.users.selected.cart.items.length > 0) {
	  // if (Number(this.products.data.length) > 0) {
	  if (hasItem) {
	    // console.log('in')
	    // code... doin delete cart item one by one
	    // 	const Response = await DeleteItem.deleteItem(this.dialogDeleteItem.product_id)
	    // var arrItemId = []
	    // console.log('this.products.data',this.products.data)
	    // console.log('this.users.selected.cart.items',this.users.selected.cart.items)

	    // forEach(this.users.selected.cart.items, item => {
	    // 	var item_id = item.attributes.id
	    // 	arrItemId.push(item_id)
	    // arrItemIdForDelAll

	    // 	console.log('item',item)
	    // 	// var success_del = false
	    // try {
	    // const Response = DeleteItem.deleteAllCartItem(item_id)
	    // const Response = DeleteItem.deleteItem(item_id)


	    // 		// success_del = true
	    // } catch (error) {
	    // 		console.log('error',error)
	    // 	// 	// this.catchHandler(error)
	    // }
	    // 	// finally {

	    // 	// }
	    // 	// console.log('item',item.attributes.product_id)
	    // console.log('inside foreach arrItemId',arrItemId)
	    // })
	    // console.log('arrItemId',arrItemId)
	    // this.arrItemIdForDelAll = arrItemId
	    if (isEmpty(this.agents.selected)) {
	      this.tryDelAll(this.users.selected.cart.id)
	    } else {
	      this.tryDelAll(this.agents.selected.cart.id)
	    }
	  }
	  this.currencyOriginProduct = ''
	  // this.closeDialogDeleteAllItem()
	  // contohnya :
	  // try {
	  // 	this.dialogDeleteItem.open = true
	  // 	this.showLoading({ text: "Deleted product..." })
	  // 	this.products.loading = true
	  // 	const Response = await DeleteItem.deleteItem(this.dialogDeleteItem.product_id)
	  // 	var ResponseItems = []
	  // 	this.cart_id = Response.data.id
	  // 	forEach(Response.included.items, items => {
	  // 		const data = {
	  // 			...items
	  // 		}
	  // 		ResponseItems.push(data)
	  // 	})
	  // 	this.setCartToProducts(ResponseItems)
	  // } catch (error) {
	  // 	this.catchHandler(error)
	  // } finally {
	  // 	this.dialogDeleteItem.open = false
	  // }
	}

	async tryDelAll(props) {
	  // console.log('tryDelAll props', props)
	  // need api for delete all item inside cart

	  // need cart_id
	  // const responseDel = await DeleteItem.deleteAllCartItem(props)
	  // console.log('responseDel', responseDel)



	  // this.closeDialogDeleteAllItem()

	  // if(isEmpty(this.agents.selected)) {
	  // 	this.users.selected.cart = responseDel.data.attributes
	  // } else {
	  // 	this.agents.selected.cart = responseDel.data.attributes
	  // }

	  if (isEmpty(this.agents.selected)) {
	    const responseDel = await CreateOrder.deleteAllCartItem(props)
	    this.closeDialogDeleteAllItem()
	    this.users.selected.cart = responseDel
	  } else {
	    const responseDel = await CreateOrder.deleteAllCartItem(props)
	    this.closeDialogDeleteAllItem()
	    this.agents.selected.cart = responseDel
	  }

	  // console.log('this.users.selected.cart',this.users.selected.cart)

	  this.setCartToProducts([])

	  // var proms = []
	  // console.log('this.globalUserId', this.globalUserId)
	  // forEach(props, async prop => {
	  // 	// console.log('prop',prop)
	  // 	const Response = DeleteItem.deleteItem(prop)
	  // 	// proms.push(DeleteItem.deleteItem(prop))
	  // 	// proms.push(await DeleteItem.deleteAllCartItem(prop))
	  // 	const optsByUserId: any = {
	  // 		params: {
	  // 			include: 'cart',
	  // 		}
	  // 	}
	  // 	const respGetUser = await Users.getOneUserCart(this.globalUserId, optsByUserId)
	  // 	console.log('respGetUser',respGetUser)

	  // 	// try {
	  // 	// 	const Response = await DeleteItem.deleteItem(prop)
	  // 	// 	console.log('Response',Response)
	  // 	// } catch (error) {

	  // 	// }
	  // })
	  // const responses = Promise.all(proms).then(response => response)
	  // console.log('responses',responses)
	}

	openDialogDeleteAllItem() {
	  this.dialogDeleteAllItem.open = true
	  // this.deleteAllItem()
	  // contohnya :
	  // this.dialogDeleteItem.open = true
	  // this.dialogDeleteItem.product_name = props.product_name
	  // this.dialogDeleteItem.product_id = props.id
	}

	noDialogDeleteAllItem() {
	  // set selected country catalog to preview
	  // this.countrySourcesIdSelected = Number(response.data.included.cart[relationshipsCart.id].attributes.ship_from)
	  // this.countrySourcesIdSelected = Number(this.users.selected.cart.ship_from)

	  // console.log('this.users.current_country_catalogs_selected',this.users.current_country_catalogs_selected)
	  // console.log('this.users.country_catalogs_selected',this.users.country_catalogs_selected)
	  if (isEmpty(this.agents.selected)) {
	    this.users.country_catalogs_selected = this.users.current_country_catalogs_selected
	  } else {
	    this.agents.country_catalogs_selected = this.agents.current_country_catalogs_selected
	  }
	  // this.users.country_catalogs_selected
	  // users.country_catalogs_selected
	  // var ship_from = Number(this.users.selected.cart.ship_from)
	  this.closeDialogDeleteAllItem()
	}

	closeDialogDeleteAllItem() {
	  this.dialogDeleteAllItem.open = false
	}

	async mounted() {
	  // console.log('setUserIdSelected data',this.users)
	  // console.log('this.contacts',this.contacts)
	  // await this.getUsers()
	  // this.getAgents()
	  this.searchUserDebounceMethod = debounce(this.getUsers, 1000)
	  this.searchAgentDebounceMethod = debounce(this.getAgents, 1000)
	  // this.searchContactDebounceMethod = debounce(, 1000)
	}

	@Watch('users.keyword')
	onUsersChanged() {
	  // debounce(this.getUsers, 500)
	  // this.getUsers()
	  // console.log(this.users.keyword)
	  this.searchUserDebounceMethod()
	}

	@Watch('agents.keyword')
	onAgentsChanged() {
	  // this.getAgents()
	  this.searchAgentDebounceMethod()
	}

	//getUsers
	// @Watch('users.keyword')
	async getUsers() {
	  // console.log('getUsers')
	  try {
	    this.users.loading = true
	    var filter = 'filter[name][like]'
	    const opts: any = {
	      params: {
	        include: 'contacts' + ',' + 'cart',
	        'page[num]': 1,
	        'page[limit]': 25,
	        sort: '-created_at',
	        'filter[name][like]': ''
	      }
	    }
	    if (!isEmpty(this.users.keyword)) {
	      opts.params[filter] = this.users.keyword
	    } else {
	      delete opts.params[filter]
	    }
	    // using exclude admin
	    const response = await Users.getUsers(opts)
	    // await Users.getOneUser(id)
	    const responseData = response.data.data
	    // const responseDataRelationshipSettings = response.data.data
	    const responseIncluded = response.data.included
	    // this.showLoading({ text: "Loading..." })
	    // const dataContacts = response.data.included.contacts
	    // const dataCart = response.data.included.cart
	    this.users.data = []
	    // this.contacts.data = []
	    // this.contacts.selected = []
	    for (const userData of responseData) {
	      const users = {
	        ...userData.attributes,
	        cart: []
	      }
	      // console.log('userData.relationships.settings',userData.relationships.settings)
	      // 	forEach(dataContacts, dataContact => {
	      // 		if (userData.attributes.id == dataContact.attributes.user_id) {
	      // 			this.cart_id = userData.relationships.cart ? userData.relationships.cart.id : null
	      // 			const data = {
	      // 				...dataContact.attributes,
	      // 				full_name: dataContact.attributes.first_name + ' ' + dataContact.attributes.last_name,
	      // 			}
	      // 			this.contacts.data.push(data)
	      // 		}
	      // 	})
	      // 	forEach(dataCart, dataCart => {
	      // 		if (userData.attributes.id == dataCart.attributes.user_id) {
	      // 			const data = {
	      // 				...dataCart.attributes,
	      // 				cart: true
	      // 			}
	      // 			users.cart = { ...data }
	      // 		}
	      // 	})
	      users.user_setting = {}
	      forEach(userData.relationships.settings, the_setting => {
	        users.user_setting[responseIncluded.settings[the_setting.id].attributes.key] = responseIncluded.settings[the_setting.id].attributes.value
	      })

	      this.users.data.push(users)
	    }
	  }
	  catch (error) {
	    this.catchHandler(error)
	  }
	  finally {
	    this.users.loading = false
	    this.closeLoading()
	  }
	}

	async getAgents() {
	  try {
	    this.agents.loading = true
	    var filter = 'filter[name][like]'
	    const opts: any = {
	      params: {
	        // include: 'contacts' + ',' + 'cart',
	        include: 'contacts' + ',' + 'cart',
	        'page[num]': 1,
	        'page[limit]': 25,
	        sort: '-created_at',
	        'filter[name][like]': '',
	        'filter[is_agent][is]': 1
	      }
	    }

	    if (!isEmpty(this.agents.keyword)) {
	      opts.params[filter] = this.agents.keyword
	    } else {
	      delete opts.params[filter]
	    }

	    const response = await Users.getUsers(opts)
	    const responseData = response.data.data
	    const responseIncluded = response.data.included
	    this.agents.data = []

	    for (const agentData of responseData) {
	      const agents = {
	        ...agentData.attributes,
	        cart: []
	      }

	      agents.agent_setting = {}
	      forEach(agentData.relationships.settings, the_setting => {
	        agents.agent_setting[responseIncluded.settings[the_setting.id].attributes.key] = responseIncluded.settings[the_setting.id].attributes.value
	      })

	      this.agents.data.push(agents)
	    }
	  } catch (error) {
	    this.catchHandler(error)
	  } finally {
	    this.agents.loading = false
	  }
	}

	async setUserIdSelected(user_id: any) {
	  this.globalUserId = user_id
	  await this.setContactTemp(user_id)
	  if (isEmpty(this.agents.selected)) {
	    this.getContactAndCartByUserId(user_id)
	  } else {
	    this.getCartByAgentId(this.globalAgentId)
	  }
	}

	async setContactTemp(user_id: any) {
	  try {
	    this.users.loading = true
	    const optsByUserId: any = {
	      params: {
	        include: 'contacts' + ',' + 'cart'
	      }
	    }

	    const response = await Users.getOneUserCart(user_id, optsByUserId)

	    const relationshipsContacts = response.data.data.relationships.contacts
	    const relationshipsCart = response.data.data.relationships.cart
	    const responseUsersData = response.data.data.attributes
	    this.usersSelectedFromSearch = responseUsersData

	    // set contact
	    this.contacts.data = []

	    if (relationshipsContacts.length > 0) {
	      // code...
	      forEach(relationshipsContacts, dataContact => {
	        // console.log('dataContact', response.data.included.contacts[dataContact.id].attributes.first_name)
	        var first_name = response.data.included.contacts[dataContact.id].attributes.first_name
	        var last_name = response.data.included.contacts[dataContact.id].attributes.last_name
	        const data = {
	          ...response.data.included.contacts[dataContact.id].attributes,
	          full_name: first_name + ' ' + last_name
	        }
	        this.contacts.data.push(data)
	      })

	      // Input shipping info user for agent
	      this.infos_user.first_name = this.contacts.data[0].first_name
	      this.infos_user.last_name = this.contacts.data[0].last_name
	      this.infos_user.company = this.contacts.data[0].company
	      this.infos_user.address_1 = this.contacts.data[0].address
	      this.infos_user.address_2 = this.contacts.data[0].subdistrict
	      this.infos_user.city = this.contacts.data[0].city_name
	      this.infos_user.city_id = this.contacts.data[0].city_id
	      this.infos_user.state = this.contacts.data[0].province
	      this.infos_user.postcode = this.contacts.data[0].postcode
	      this.infos_user.country = this.contacts.data[0].country
	      this.infos_user.phone = this.contacts.data[0].phone
	      this.infos_user.email = this.contacts.data[0].email
	    }

	  } catch (error) {
	    this.catchHandler(error)
	  } finally {
	    this.users.loading = false
	    this.closeLoading()
	    this.contacts.data = []
	  }
	}

	// Agent
	setAgentIdSelected(agent_id: any) {
	  this.globalAgentId = agent_id
	  this.contacts.selected = []
	  this.getCartByAgentId(agent_id)
	}

	async getCartByAgentId(agent_id: any) {
	  try {
	    this.agents.loading = true
	    const optsByAgentId: any = {
	      params: {
	        include: 'contacts' + ',' + 'cart'
	      }
	    }

	    const response = await Users.getOneUserCart(agent_id, optsByAgentId)

	    // set the user country catalog
	    // check the cart first if has cart the first cart item has country_id, use it for the selected products country
	    var arrCountryCat = []
	    forEach(response.data.data.attributes.country_catalogs, (country_cat, index) => {
	      var objCountrycat = {
	        id: country_cat,
	        name: response.data.data.attributes.country_catalog_names[index]
	      }

	      arrCountryCat.push(objCountrycat)
	    })
	    this.agents.country_catalogs = arrCountryCat

	    const relationshipsContacts = response.data.data.relationships.contacts
	    const relationshipsCart = response.data.data.relationships.cart
	    const responseAgentsData = response.data.data.attributes
	    this.agentsSelectedFromSearch = responseAgentsData

	    // set contact
	    // this.contacts.data = []

	    // forEach(relationshipsContacts, dataContact => {
	    // 	// console.log('dataContact', response.data.included.contacts[dataContact.id].attributes.first_name)
	    // 	var first_name = response.data.included.contacts[dataContact.id].attributes.first_name
	    // 	var last_name = response.data.included.contacts[dataContact.id].attributes.last_name
	    // 	const data = {
	    // 		...response.data.included.contacts[dataContact.id].attributes,
	    // 		full_name: first_name + ' ' + last_name,
	    // 	}
	    // 	this.contacts.data.push(data)
	    // })

	    // If selected agent doesn't have contacts
	    // if(isEmpty(this.contacts.data)) {
	    // 	this.contacts.selected = []
	    // }

	    //set cart
	    // console.log('dataCart', response.data.included.cart[relationshipsCart.id].attributes)
	    // console.log('sini 3',response.data.data.relationships.hasOwnProperty('cart'))
	    if (response.data.data.relationships.hasOwnProperty('cart')) {
	      if (!isEmpty(response.data.included.cart[relationshipsCart.id].attributes.items)) {

	        const dataCart = {
	          ...response.data.included.cart[relationshipsCart.id].attributes,
	          cart: true,
	          lengthItem: response.data.included.cart[relationshipsCart.id].attributes.items.length
	        }

	        // console.log('dataCart',dataCart)

	        this.agents.selected.cart = dataCart
	        this.cart_id = Number(response.data.included.cart[relationshipsCart.id].attributes.id)

	        this.countrySourcesIdSelected = Number(response.data.included.cart[relationshipsCart.id].attributes.ship_from)

	        // response.data.data.attributes.country_catalogs
	        if (response.data.data.attributes.country_catalogs.length > 0) {
	          // console.log('response.data.data.attributes.country_catalogs', response.data.data.attributes.country_catalogs)
	          // console.log('response.data.data.attributes.country_catalogs.findIndex(44)', response.data.data.attributes.country_catalogs.indexOf(Number(this.countrySourcesIdSelected)))
	          var theIndex = response.data.data.attributes.country_catalogs.indexOf(Number(this.countrySourcesIdSelected))
	          // console.log('theIndex',theIndex)

	          this.agents.current_country_catalogs_selected = {
	            id: Number(response.data.included.cart[relationshipsCart.id].attributes.ship_from),
	            name: response.data.data.attributes.country_catalog_names[theIndex]
	          }

	          this.agents.country_catalogs_selected = {
	            id: Number(response.data.included.cart[relationshipsCart.id].attributes.ship_from),
	            name: response.data.data.attributes.country_catalog_names[theIndex]
	          }
	        }

	        // set the user country catalog
	        // check the cart first if has cart the first cart item has country_id,
	        // set the selected for the selected products country
	        // this.users.country_catalogs = arrCountryCat

	        // if(Number(response.data.included.cart[relationshipsCart.id].attributes.ship_from) === 44) {
	        // 	this.users.country_catalogs_selected = {
	        // 		id: Number(response.data.included.cart[relationshipsCart.id].attributes.ship_from),
	        // 		name: "China"
	        // 	}
	        // }

	        // selected by check the first item country id inside cart
	        // Number(response.data.included.cart[relationshipsCart.id].attributes.ship_from)

	        // console.log('this.agents.selected.cart.items',this.agents.selected.cart.items)

	        await this.setCartToProducts(this.agents.selected.cart.items)
	      } else {
	        this.agents.selected.cart.lengthItem = 0

	        // reset product table
	        this.products.data = []
	        this.showSnackbar({
	          text: 'Cart Items is empty',
	          color: 'info',
	          timeout: 2000
	        })
	      }
	    } else {
	      this.products.data = []
	      this.showSnackbar({
	        text: 'Agent Cart is empty',
	        color: 'info',
	        timeout: 2000
	      })
	    }
	  } catch (error) {
	    this.catchHandler(error)
	  } finally {
	    this.agents.loading = false
	    this.closeLoading()
	  }
	}

	async getContactAndCartByUserId(user_id: any) {

	  try {
	    this.users.loading = true
	    const optsByUserId: any = {
	      params: {
	        include: 'contacts' + ',' + 'cart'
	      }
	    }

	    const response = await Users.getOneUserCart(user_id, optsByUserId)

	    // set the user country catalog
	    // check the cart first if has cart the first cart item has country_id, use it for the selected products country
	    var arrCountryCat = []
	    forEach(response.data.data.attributes.country_catalogs, (country_cat, index) => {
	      var objCountrycat = {
	        id: country_cat,
	        name: response.data.data.attributes.country_catalog_names[index]
	      }

	      arrCountryCat.push(objCountrycat)
	    })
	    this.users.country_catalogs = arrCountryCat
	    if (response.data.data.attributes.default_shipping_to != null) {
	      this.users.shipping_to_country = response.data.data.attributes.default_shipping_to.name
	      this.users.order_currency = response.data.data.attributes.default_shipping_to.currency
	    }

	    const relationshipsContacts = response.data.data.relationships.contacts
	    const relationshipsCart = response.data.data.relationships.cart
	    const responseUsersData = response.data.data.attributes
	    this.usersSelectedFromSearch = responseUsersData

	    // set contact
	    this.contacts.data = []

	    forEach(relationshipsContacts, dataContact => {
	      var first_name = response.data.included.contacts[dataContact.id].attributes.first_name
	      var last_name = response.data.included.contacts[dataContact.id].attributes.last_name
	      const data = {
	        ...response.data.included.contacts[dataContact.id].attributes,
	        full_name: first_name + ' ' + last_name
	      }
	      this.contacts.data.push(data)
	    })

	    //set cart
	    if (response.data.data.relationships.hasOwnProperty('cart')) {
	      if (!isEmpty(response.data.included.cart[relationshipsCart.id].attributes.items)) {

	        const dataCart = {
	          ...response.data.included.cart[relationshipsCart.id].attributes,
	          cart: true,
	          lengthItem: response.data.included.cart[relationshipsCart.id].attributes.items.length
	        }

	        this.users.selected.cart = dataCart
	        this.cart_id = Number(response.data.included.cart[relationshipsCart.id].attributes.id)

	        this.countrySourcesIdSelected = Number(response.data.included.cart[relationshipsCart.id].attributes.ship_from)

	        if (response.data.data.attributes.country_catalogs.length > 0) {
	          var theIndex = response.data.data.attributes.country_catalogs.indexOf(Number(this.countrySourcesIdSelected))

	          this.users.current_country_catalogs_selected = {
	            id: Number(response.data.included.cart[relationshipsCart.id].attributes.ship_from),
	            name: response.data.data.attributes.country_catalog_names[theIndex]
	          }

	          this.users.country_catalogs_selected = {
	            id: Number(response.data.included.cart[relationshipsCart.id].attributes.ship_from),
	            name: response.data.data.attributes.country_catalog_names[theIndex]
	          }
	        }

	        // set the user country catalog
	        // check the cart first if has cart the first cart item has country_id,
	        // set the selected for the selected products country
	        // this.users.country_catalogs = arrCountryCat

	        // if(Number(response.data.included.cart[relationshipsCart.id].attributes.ship_from) === 44) {
	        // 	this.users.country_catalogs_selected = {
	        // 		id: Number(response.data.included.cart[relationshipsCart.id].attributes.ship_from),
	        // 		name: "China"
	        // 	}
	        // }

	        // selected by check the first item country id inside cart
	        // Number(response.data.included.cart[relationshipsCart.id].attributes.ship_from)

	        // console.log('this.users.selected.cart.items',this.users.selected.cart.items)
	        await this.setCartToProducts(this.users.selected.cart.items)
	      } else {
	        this.users.selected.cart.lengthItem = 0

	        // reset product table
	        this.products.data = []
	        this.showSnackbar({
	          text: 'Cart Items is empty',
	          color: 'info',
	          timeout: 2000
	        })
	      }
	    } else {
	      this.products.data = []
	      this.showSnackbar({
	        text: 'User Cart is empty',
	        color: 'info',
	        timeout: 2000
	      })
	    }
	  } catch (error) {
	    this.catchHandler(error)
	  } finally {
	    console.log(this.users)
	    this.users.loading = false
	    this.closeLoading()
	  }
	}

	//Details
	setDetails(data: any) {
	  this.details = []
	  this.details = {
	    data: data,
	    totalProductCost: data.total_goods_value,
	    customCharge: data.total_custom_charge,
	    // totalLogisticCost: data.total_logistic_all_in,
	    totalLogisticCost: data.total_logistic_charge,
	    currency: data.currency_order,
	    weight: data.total_weight,
	    grand_total: data.grand_total
	  }
	}

	//Set Cart // sini
	async setCartToProducts(data: any) {
	  // console.log('data',data)

	  // console.log('isEmpty', isEmpty(data))
	  try {
	    this.products.data = []
	    this.details = []
	    // if (data != null) {
	    // console.log('!isEmpty(data)',!isEmpty(data))
	    if (!isEmpty(data)) {

	      this.products.data = []
	      this.products.loading = true
	      this.showLoading({ text: 'Loading...' })
	      this.payload_calculation.products = []
	      // console.log('=>',data)
	      forEach(data, items => {
	        // console.log('items',items)
	        // console.log('items.attributes.product.attributes.variants == null',items.attributes.product.attributes.variants == null)
	        if (items.attributes.product.attributes.variants === null) {
	          // console.log('if')
	          const payload_product = {
	            id: items.attributes.product_id,
	            variants: []
	          }

	          for (const variants of items.attributes.variants) {
	            const data = {
	              quantity: variants.quantity,
	              attributes: variants.attributes
	            }
	            payload_product.variants.push(data)
	          }
	          // console.log('payload_product if',payload_product)
	          this.payload_calculation.products.push(payload_product)
	        } else {
	          // console.log('else')
	          const payload_product = {
	            id: items.attributes.product_id,
	            quantity: items.attributes.variants[0].quantity
	          }
	          // 	console.log('payload_product else',payload_product)
	          this.payload_calculation.products.push(payload_product)
	        }
	      })


	      let responseSettings: any = []

	      // console.log('this.user.selected',this.user.selected)
	      // console.log('this.agents.selected',this.agents.selected)

	      if (isEmpty(this.agents.selected)) {
	        // console.log('if')
	        if (typeof this.users.selected.user_setting.default_shipping_to === 'undefined') {
	          var default_shipping_to_country_id = this.users.selected.country_signup
	        } else {
	          var default_shipping_to_country_id = this.users.selected.user_setting.default_shipping_to
	        }
	        const optsSettings: any = {
	          params: {
	            'filter[country_id][is]': Number(default_shipping_to_country_id)
	          }
	        }
	        responseSettings = await SettingsService.getSettings(optsSettings)
	      } else {
	        // console.log('else')
	        if (typeof this.agents.selected.agent_setting.default_shipping_to === 'undefined') {
	          var default_shipping_to_country_id = this.agents.selected.country_signup
	        } else {
	          var default_shipping_to_country_id = this.agents.selected.agent_setting.default_shipping_to
	        }


	        const optsSettings: any = {
	          params: {
	            'filter[country_id][is]': Number(default_shipping_to_country_id)
	          }
	        }
	        responseSettings = await SettingsService.getSettings(optsSettings)
	      }

	      // console.log('responseSettings',responseSettings)

	      // const responseSettings = await SettingsService.getSettings(optsSettings)
	      const global_setting_by_key = responseSettings.data.data.find(x => x.attributes.key === 'official_weight_cost')
	      const get_global_setting_logistic_per_unit = Number(global_setting_by_key.attributes.value)

	      const global_setting_currency_by_key = responseSettings.data.data.find(x => x.attributes.key === 'official_weight_currency')
	      const get_global_setting_currency = global_setting_currency_by_key.attributes.value

	      this.payload_calculation.logistic_per_unit = get_global_setting_logistic_per_unit


	      var lastProduct = {
	        'attributes': {
	          'product': {
	            'attributes': {
	              'country_id': null
	            }
	          }
	        }
	      }
	      lastProduct = last(data)
	      // console.log('lastProduct',lastProduct)
	      // sini ambil dari last product country
	      if (lastProduct.attributes.product.attributes.country_id === null) {
	        // code...
	        var last_product_country_id = 44
	        this.showSnackbar({
	          text: 'Product tidak punya country id',
	          color: 'red',
	          timeout: 10000
	        })
	      } else {
	        var last_product_country_id = Number(lastProduct.attributes.product.attributes.country_id)
	      }
	      // lastProduct.attributes.product.attributes.country_id
	      // console.log('last_product_country_id',last_product_country_id)
	      this.payload_calculation.ship_from = last_product_country_id

	      if (isEmpty(this.agents.selected)) {
	        // console.log('if')
	        if (typeof this.users.selected.user_setting.default_shipping_to === 'undefined') {
	          var default_shipping_to_country_id = this.users.selected.country_signup
	        } else {
	          var default_shipping_to_country_id = this.users.selected.user_setting.default_shipping_to
	        }
	        this.payload_calculation.ship_to = Number(default_shipping_to_country_id)
	      } else {
	        // console.log('else')
	        if (typeof this.agents.selected.agent_setting.default_shipping_to === 'undefined') {
	          var default_shipping_to_country_id = this.agents.selected.country_signup
	        } else {
	          var default_shipping_to_country_id = this.agents.selected.agent_setting.default_shipping_to
	        }
	        this.payload_calculation.ship_to = Number(default_shipping_to_country_id)
	      }
	      // console.log('data',data)
	      // diganti dari product country
	      // this.payload_calculation.ship_to = Number(this.users.selected.user_setting.default_shipping_to)

	      this.payload_calculation.currency_order = get_global_setting_currency

	      // sini ambil dari milik product
	      var respCurrency_origin = await CountryService.getCountryById(Number(last_product_country_id))
	      this.payload_calculation.currency_origin = respCurrency_origin.data.data.attributes.currency


	      var return_calculation = await CreateOrder.calculationNonCatalog(this.payload_calculation)
	      // update handling charge

	      // const global_setting_by_key = responseSettings.data.data.find(x => x.attributes.key === "official_weight_cost")
	      var checkHasHandlingCharge = return_calculation.data.custom_logistic_charges.filter(x => x.name === 'Handling Charge')


	      var calculation_check = {
	        data: []
	      }
	      calculation_check[data] = {}
	      if (checkHasHandlingCharge.length === 0) {

	        // return_calculation.data.custom_logistic_charges

	        var handling_charge_no_vat = return_calculation.data.total_goods_value * 5 / 100

	        var arrHandlingCharge = {
	          'type': 'custom-optional',
	          'group': 'Handling Charge',
	          'name': 'Handling Charge',
	          'value': 0,
	          'price': Number(handling_charge_no_vat),
	          'vat': 0
	        }

	        var payload_after_add_handling_charge = this.payload_calculation
	        payload_after_add_handling_charge.custom_logistic_charges.push(arrHandlingCharge)

	        // var return_calculation_after_add_handling_charge = await CreateOrder.calculationNonCatalog(this.payload_calculation)
	        var return_calculation_after_add_handling_charge = await CreateOrder.calculationNonCatalog(payload_after_add_handling_charge)
	        calculation_check = return_calculation_after_add_handling_charge
	        this.calculationResult = return_calculation_after_add_handling_charge
	        var calculation = return_calculation_after_add_handling_charge.data.products
	        // console.log('return_calculation_after_add_handling_charge',return_calculation_after_add_handling_charge.data)

	      }
	      else {
	        var handling_charge_no_vat = return_calculation.data.total_goods_value * 5 / 100
	        var arrHandlingCharge = {
	          'type': 'custom-optional',
	          'group': 'Handling Charge',
	          'name': 'Handling Charge',
	          'value': 0,
	          'price': Number(handling_charge_no_vat),
	          'vat': 0
	        }

	        var payload_updated_handling_charge = this.payload_calculation

	        var updated_custom_charge = payload_updated_handling_charge.custom_logistic_charges.filter(x => x.name !== 'Handling Charge')

	        updated_custom_charge.push(arrHandlingCharge)

	        payload_updated_handling_charge.custom_logistic_charges = updated_custom_charge

	        // console.log('filter',payload_updated_handling_charge.custom_logistic_charges.filter(x => x.name === "Handling Charge"))

	        var return_calculation_after_updated_handling_charge = await CreateOrder.calculationNonCatalog(payload_updated_handling_charge)
	        calculation_check = return_calculation_after_updated_handling_charge


	        // calculation_check = return_calculation
	        this.calculationResult = return_calculation_after_updated_handling_charge
	        var calculation = return_calculation_after_updated_handling_charge.data.products
	        // old
	        // this.calculationResult = return_calculation
	        // var calculation = return_calculation.data.products
	      }

	      // console.log('data',data)
	      var data_mix = []
	      for (const atad of data) {
	        let quantityPerUnit = null
	        let isBulk = false

	        if (atad.attributes.product.attributes.pricing_type === 'bulkPrice') {
	          quantityPerUnit = atad.attributes.quantity_per_unit
	          isBulk = true
	        }

	        if (atad.attributes.product.attributes.variants == null) {
	          const product = {
	            ...atad.attributes,
	            product_calculation: null,
	            variants_calculation: null,
	            quantity_per_unit: quantityPerUnit,
	            is_bulk: isBulk,
	            unit_name: upperCase(atad.attributes.unit_name)
	          }
	          for (const calcul of calculation) {
	            if (atad.attributes.product_id == calcul.id) {
	              product.variants_calculation = calcul.variants,
	              product.product_calculation = calcul
	            }
	          }
	          // console.log('product+++++++',product)
	          data_mix.push(product)
	        } else {
	          const product = {
	            ...atad.attributes,
	            product_calculation: null,
	            variants_calculation: []
	          }
	          for (const calcul of calculation) {
	            if (atad.attributes.product_id == calcul.id) {
	              const data_variant = {
	                ...calcul,
	                attributes: atad.attributes.variants[0].attributes,
	                quantity: atad.attributes.variants[0].quantity,
	                quantity_per_unit: quantityPerUnit,
	                unit_name: upperCase(atad.attributes.unit_name),
	                is_bulk: isBulk
	              }
	              product.product_calculation = calcul
	              product.variants_calculation.push(data_variant)
	            }
	          }
	          data_mix.push(product)
	        }
	      }
	      // console.log('data_mix',data_mix)
	      for (const items of data_mix) {
	        let is_bulk = false
	        if (items.product.attributes.pricing_type === 'bulkPrice') {
	          is_bulk = true
	        }
	        const data = {
	          id: items.id,
	          product_id: items.product_id,
	          product_name: items.product_name,
	          original_name: items.original_name,
	          quantity: items.product_calculation.total_quantity,
	          cost: items.product_calculation.total_goods_value,
	          logistic_cost: items.product_calculation.total_logistic_all_in,
	          landed_cost: items.product_calculation.landed_cost_all_in,
	          currency: items.currency,
	          original_url: items.product.attributes.original_url,
	          variants: items.variants_calculation,
	          variants_original: items.variants,
	          image: items.product.attributes.image,
	          imagesViewer: [items.product.attributes.image],
	          quantity_per_unit: items.quantity_per_unit,
	          unit_name: upperCase(items.unit_name),
	          is_bulk: is_bulk
	        }

	        forEach(data.variants, (var_item, index) => {

	          var_item.imagesViewerVariant = []
	          var_item.imagesViewerVariant.push(data.variants_original[index].image)

	          var_item.image_variant = data.variants_original[index].image

	        })

	        // forEach(data.variants_original, var_item => {
	        // 	var_item.images = []
	        // 	var_item.images.push(var_item.image)
	        // })
	        // console.log('this.products',this.products)
	        this.products.data.push(data)
	      }
	      //old
	      // this.setDetails(return_calculation.data)
	      // console.log('before set detail return_calculation.data',return_calculation.data)
	      // console.log('before set detail calculation',calculation)
	      this.setDetails(calculation_check.data)
	    }
	  } catch (error) {
	    // this.catchHandler(error)
	  } finally {
	    this.products.loading = false
	    // this.payload_calculation.products = []
	    this.closeLoading()
	    // sini yogie
	    // mencari indikasi dari ketika barang di tambahkan dari semula si user blm ada item sama sekali di dalam cart,
	    //  untuk digunakan di fungsi setSourcesIdSelected
	    // console.log('this.users.selected.cart',this.users.selected.cart)
	    // console.log('this.products.data',this.products.data)
	  }
	}
	setHeaderVariants(props: any) {
	  try {
	    for (const variants of props.item.variants) {
	      if (variants.attributes.length == '1') {
	        this.variants.headers = [
	          {
	            text: 'Image',
	            value: 'price_per_item',
	            sortable: false
	          },
	          {
	            text: variants.attributes[0].name,
	            sortable: false,
	            value: 'option'
	          }, {
	            text: 'Product Cost',
	            value: 'price_per_item',
	            sortable: false
	          }, {
	            text: 'Logistic Cost',
	            value: 'logistic_cost',
	            sortable: false
	          }, {
	            text: 'Landed Cost',
	            value: 'logisti_cost',
	            sortable: false
	          }, {
	            text: 'Quantity',
	            value: 'quantity',
	            sortable: false
	          }, {
	            text: 'Subtotal',
	            value: 'qunatity',
	            sortable: false
	          }]
	      } else {
	        this.variants.headers = [
	          {
	            text: 'Image',
	            value: 'price_per_item',
	            sortable: false
	          },
	          {
	            text: variants.attributes[0].name,
	            sortable: false,
	            value: 'option'
	          }, {
	            text: variants.attributes[1].name,
	            sortable: false,
	            value: 'option'
	          }, {
	            text: 'Product Cost',
	            value: 'price_per_item',
	            sortable: false
	          }, {
	            text: 'Logistic Cost',
	            value: 'logistic_cost',
	            sortable: false
	          }, {
	            text: 'Landed Cost',
	            value: 'logistic_cost',
	            sortable: false
	          }, {
	            text: 'Quantity',
	            value: 'quantity',
	            sortable: false
	          }, {
	            text: 'Subtotal',
	            value: 'quantity',
	            sortable: false
	          }]
	      }
	    }
	  } catch (error) {
	    this.catchHandler(error)
	  } finally { }
	}


	//Modal Products
	@Watch('addProduct.keyword.data')
	async onAddProductModalOpen() {
	  // console.log('this.countrySourcesIdSelected',this.countrySourcesIdSelected)
	  //  sini yogie
	  // console.log('this.users.selected',this.users.selected)
	  // console.log('this.users',this.users)
	  // console.log('this.agents',this.agents)



	  if (isEmpty(this.users.selected)) {
	    this.showSnackbar({
	      text: 'Choose User first !',
	      color: 'info',
	      timeout: 2000
	    })
	  } else {

	    // cek jika pakai agent atau user lsg
	    // this.users.selected.length
	    // jika array maka kosong, krn jika terisi bentuknya object
	    // data instanceof Array

	    // console.log('this.users.selected instanceof Array',this.users.selected instanceof Array)

	    // console.log('this.agents.selected instanceof Array',this.agents.selected instanceof Array)

	    // if (this.users.selected instanceof Array && this.agents.selected instanceof Array) {
	    // 	// code...
	    // 	this.usingAgent = false
	    // }

	    // pengecekan pakai agen atau tidak
	    // if (!(this.users.selected instanceof Array) && !(this.agents.selected instanceof Array)) {
	    // 	// code...
	    // 	this.showSnackbar({
	    // 		text: "Choose Products Sources First !",
	    // 		color: "info",
	    // 		timeout: 2000
	    // 	})
	    // } else {
	    // 	try {
	    // 		this.addProduct.open = true
	    // 		this.addProduct.loading = true
	    // 		var filter = 'filter[title][like]'
	    // 		const opts: any = {
	    // 			params: {
	    // 				'page[num]': 1,
	    // 				'page[limit]': 25,
	    // 				sort: '-created_at',
	    // 				'filter[title][like]': '',
	    // 				'filter[country_id][is]': this.countrySourcesIdSelected,
	    // 				include: 'bulk_prices'
	    // 	this.usingAgent = true
	    // }

	    // console.log('this.usingAgent',this.usingAgent)

	    if (this.usingAgent === true) {
	      // code...
	      if (this.agents.country_catalogs_selected === null) {
	        this.showSnackbar({
	          text: 'Choose Products Sources First !aaa',
	          color: 'info',
	          timeout: 2000
	        })
	      } else {
	        try {
	          this.addProduct.open = true
	          this.addProduct.loading = true
	          var filter = 'filter[title][like]'
	          const opts: any = {
	            params: {
	              'page[num]': 1,
	              'page[limit]': 25,
	              sort: '-created_at',
	              'filter[title][like]': '',
	              'filter[country_id][is]': this.countrySourcesIdSelected,
	              include: 'bulk_prices'
	            }
	          }
	          if (!isEmpty(this.addProduct.keyword.data)) {
	            opts.params[filter] = this.addProduct.keyword.data
	          } else if (this.addProduct.keyword.action == true) {
	            delete opts.params[filter]
	          } else {
	            this.addProduct.open = false
	            this.addProduct.keyword.action = true
	          }

	          const response = await Product.getProductsLists(opts)
	          const responseData = response.data

	          this.addProduct.data = []
	          for (const productData of responseData) {
	            const product = {
	              ...productData.attributes
	            }
	            this.addProduct.data.push(product)
	          }
	        } catch (error) {
	          this.catchHandler(error)
	        } finally {
	          this.addProduct.loading = false
	        }
	      }
	    } else {
	      if (this.users.selected.country_catalogs === null) {
	        // code...
	        this.showSnackbar({
	          text: 'Choose Products Sources First !',
	          color: 'info',
	          timeout: 2000
	        })
	      } else {
	        try {
	          this.addProduct.open = true
	          this.addProduct.loading = true
	          var filter = 'filter[title][like]'
	          const opts: any = {
	            params: {
	              'page[num]': 1,
	              'page[limit]': 25,
	              sort: '-created_at',
	              'filter[title][like]': '',
	              'filter[country_id][is]': this.countrySourcesIdSelected
	            }
	          }
	          if (!isEmpty(this.addProduct.keyword.data)) {
	            opts.params[filter] = this.addProduct.keyword.data
	          } else if (this.addProduct.keyword.action == true) {
	            delete opts.params[filter]
	          } else {
	            this.addProduct.open = false
	            this.addProduct.keyword.action = true
	          }

	          const response = await Product.getProductsLists(opts)
	          const responseData = response.data

	          this.addProduct.data = []
	          for (const productData of responseData) {
	            const product = {
	              ...productData.attributes
	            }
	            this.addProduct.data.push(product)
	          }
	        } catch (error) {
	          this.catchHandler(error)
	        } finally {
	          this.addProduct.loading = false
	        }
	      }
	    }

	  }
	}
	async getVariants() {
	  this.selectedBulkPrice = []
	  try {
	    this.variations.loading = true
	    const opts: any = {
	      params: {
	        'convert_to': 'IDR',
	        include: 'variations,bulk_prices'
	      }
	    }
	    const response = await Product.getOneProduct(this.addProduct.selected.id, opts)
	    const responseVariations = response.included.variations
	    const responsedata = response.data.attributes
	    const responseDataRelationships = response.data.relationships
	    const responseBulkPrices = response.included.bulk_prices

	    this.variations.getData = []
	    // console.log('responsedata.variants',responsedata.variants)

	    // Bulk Price / Bundle Price
	    let is_bulk = false
	    const bulkPricesData = []
	    if (responseBulkPrices.length != 0) {
	      is_bulk = true
	      forEach(responseBulkPrices, bulkPrice => {
	        let dataBulk = {
	          id: bulkPrice.attributes.id,
	          product_id: bulkPrice.attributes.product_id,
	          unit_name: bulkPrice.attributes.unit_name,
	          quantity: bulkPrice.attributes.quantity,
	          price: bulkPrice.attributes.price,
	          label: '1' + ' ' + upperCase(bulkPrice.attributes.unit_name) + ' ' + '(' + bulkPrice.attributes.quantity + ' pcs)',
	          unit_name_uppercase: upperCase(bulkPrice.attributes.unit_name)
	        }
	        bulkPricesData.push(dataBulk)
	      })
	      this.selectedBulkPrice = bulkPricesData[0]
	    }

	    // if null so the products has variants
	    if (responsedata.variants == null) {
	      // console.log('NOT NULL responseDataRelationships',responseDataRelationships)
	      forEach(responseDataRelationships.variations, the_var => {
	        // var.id
	        // console.log('responseVariations[var.id].attributes.image_url',responseVariations[the_var.id].attributes.image_url)
	        // console.log('responseVariations[var.id].attributes',responseVariations[the_var.id].attributes)
	        const dataTemp = responseVariations[the_var.id].attributes
	        const data = {
	          ...dataTemp,
	          weight: responsedata.weight,
	          image_of_data: responsedata.image
	          // imagesViewer: [responsedata.image]
	          // image_of_data: dataTemp.image_url
	        }
	        // console.log('data',data)
	        this.variations.getData.push(data)
	      })
	      // console.log('this.variations.getData',this.variations.getData)
	      // forEach(responseVariations, dataVariant => {
	      // 	const data = {
	      // 		...dataVariant.attributes,
	      // 		weight: responsedata.weight,
	      // 		image_of_data: responsedata.image
	      // 	}
	      // 	this.variations.getData.push(data)
	      // 	console.log('data',data)
	      // })
	    } else {
	      // console.log('NULL responseDataRelationships',responseDataRelationships)
	      this.variations.getData.push(responsedata.variants)
	    }


	    this.variations.variants = []
	    // console.log('this.variations.getData',this.variations.getData)
	    for (const variation of this.variations.getData) {
	      // console.log('variation',variation)
	      const data = {
	        variation_id: variation.id,
	        price_per_item: variation.regular_price,
	        sku: variation.sku,
	        quantity: 0,
	        image: variation.image_url ? variation.image_url : variation.image_of_data,
	        attributes: variation.options_values,
	        bulk_data: bulkPricesData,
	        is_bulk: is_bulk
	      }
	      // console.log('data',data)
	      this.variations.variants.push(data)
	    }
	    // console.log('this.variations.variants',this.variations.variants)
	    this.variations.headers = []
	    if (this.variations.getData[0].options_values.length == '1') {
	      this.variations.headers = [
	        {
	          text: 'Image',
	          value: 'image',
	          sortable: false
	        },
	        {
	          text: this.variations.getData[0].options_values[0].name,
	          sortable: false,
	          value: 'option'
	        }, {
	          text: 'Product Cost',
	          value: 'price_per_item',
	          sortable: false
	        }, {
	          text: 'Quantity',
	          value: 'quantity',
	          sortable: false
	        }, {
	          text: 'Subtotal',
	          value: 'subtotal',
	          sortable: false
	        }
	      ]
	    } else {
	      this.variations.headers = [
	        {
	          text: 'Image',
	          value: 'image',
	          sortable: false
	        },
	        {
	          text: this.variations.getData[0].options_values[0].name,
	          sortable: false,
	          value: 'option'
	        }, {
	          text: this.variations.getData[0].options_values[1].name,
	          sortable: false,
	          value: 'option'
	        }, {
	          text: 'Product Cost',
	          value: 'price_per_item',
	          sortable: false
	        }, {
	          text: 'Quantity',
	          value: 'quantity',
	          sortable: false
	        }, {
	          text: 'Subtotal',
	          value: 'subtotal',
	          sortable: false
	        }
	      ]
	    }
	  }
	  catch (error) {
	    this.catchHandler(error)
	  }
	  finally {
	    this.variations.loading = false
	  }
	}
	onAddProductModalClosed() {
	  this.addProduct.open = false
	  this.addProduct.keyword.action = false
	  this.addProduct.selected = null
	  this.variations.variants = []
	}
	//Order Date
	onSelectOrderDateCancel() {
	  this.orderDateDialogOpen = false
	}

	onSelectOrderDateInput(event) {
	  this.orderDate.order_date_tempDate = event
	}

	onSelectOrderDateDone() {
	  if (this.orderDate.order_date_tempDate) {
	    this.orderDate.order_date_date = this.orderDate.order_date_tempDate
	    this.orderDate.order_date_dateFormatted = this.toFullDate(
	      this.orderDate.order_date_tempDate
	    )
	  }
	  this.orderDateDialogOpen = false
	}
	async onAddProductModalSave(data: any, product: any, variants: any) {
	  // onAddProductModalSave
	  console.log('data', data)
	  // console.log('product',product)
	  // console.log('variants',variants)
	  // console.log('this.users.selected.cart',this.users.selected.cart)
	  try {
	    this.showLoading({ text: 'Add product...' })
	    var quantityZero = false
	    var valid = await this.$validator.validateAll('addProduct')

	    if (!this.currencyOriginProduct) {
	      this.currencyOriginProduct = product.original_currency
	    }
	    if (valid && data.id != null) {
	      this.payload_calculation.products = []
	      if (product.variants == null) {
	        const calculation_product = {
	          id: product.id,
	          variants: []
	        }
	        forEach(variants, variant => {
	          const data = {
	            quantity: variant.quantity,
	            attributes: variant.attributes
	          }
	          calculation_product.variants.push(data)
	        })
	        this.payload_calculation.products.push(calculation_product)
	      } else {
	        const calculation_product = {
	          id: product.id,
	          quantity: variants[0].quantity
	        }
	        this.payload_calculation.products.push(calculation_product)
	      }

	      // console.log('this.users',this.users)

	      // check if using agent or not
	      // if(isEmpty(this.agents.selected)) {
	      // 	this.users.country_catalogs_selected = this.users.current_country_catalogs_selected
	      // } else {
	      // 	this.agents.country_catalogs_selected = this.agents.current_country_catalogs_selected
	      // }

	      // jika user tidak memiliki default_shipping_to
	      // // this.users.selected.country_signup

	      // // this.users.selected.user_setting,hasOwnProperty("default_shipping_to")
	      if (this.users.selected.user_setting.hasOwnProperty('default_shipping_to')) {
	        // code...
	        this.payload_calculation.ship_to = this.users.selected.user_setting.default_shipping_to
	      } else {
	        if (this.users.selected.country_signup !== null || this.users.selected.country_signup !== '') {
	          // code...
	          this.payload_calculation.ship_to = this.users.selected.country_signup
	        }
	      }

	      this.payload_calculation.ship_from = product.country_id

	      // CountryService.getCountryById(country_id)
	      // ambil ini dari ship from dan ship to aja

	      //  ship_from
	      var respFrom = await CountryService.getCountryById(Number(this.payload_calculation.ship_from))
	      this.payload_calculation.currency_origin = respFrom.data.data.attributes.currency

	      //  ship_to
	      var respTo = await CountryService.getCountryById(Number(this.payload_calculation.ship_to))
	      this.payload_calculation.currency_order = respTo.data.data.attributes.currency

	      // yogie set // sini
	      // console.log('this.payload_calculation',this.payload_calculation)
	      const return_calculation = await CreateOrder.calculationNonCatalog(this.payload_calculation)
	      this.calculationResult = return_calculation
	      const calculation = return_calculation.data.products[0]
	      const variants_mix = []
	      if (product.variants == null) {
	        for (const variant of variants) {
	          const data_variant = {
	            ...variant,
	            calculation: null
	          }
	          for (const variant_calculation of calculation.variants) {
	            if (variant.variation_id == variant_calculation.variant_id) {
	              data_variant.calculation = variant_calculation
	            }
	          }
	          variants_mix.push(data_variant)
	        }
	      } else {
	        for (const variant of variants) {
	          const data_variant = {
	            ...variant,
	            calculation: {
	              ...calculation,
	              quantity: Number(variant.quantity)
	            }
	          }
	          variants_mix.push(data_variant)
	        }
	      }

	      var weight = null
	      if (product.rules.product_rule.length > 0) {
	        weight = product.rules.product_rule[0].attributes.real_weight
	      } else if (product.rules.category_rule != null) {
	        weight = product.rules.category_rule.attributes.weight * 1000
	      } else { weight = product.weight }

	      // sini
	      let data_ship_to = null
	      // if(isEmpty(this.agents.selected)) {
	      // 	data_ship_to = Number(this.usersSelectedFromSearch.country_signup)
	      // } else {
	      // 	data_ship_to = Number(this.agentsSelectedFromSearch.country_signup)
	      // }

	      if (this.users.selected.user_setting.hasOwnProperty('default_shipping_to')) {
	        // code...
	        data_ship_to = this.users.selected.user_setting.default_shipping_to
	      } else {
	        if (this.users.selected.country_signup !== null || this.users.selected.country_signup !== '') {
	          // code...
	          data_ship_to = this.users.selected.country_signup
	          this.showSnackbar({
	            text: 'User' + ' ' + this.users.selected.name + ' ' + 'Tidak memiliki Default Shipping To, jadi menggunakan Country SignUp',
	            color: 'blue',
	            timeout: 7000
	          })
	        } else {
	          this.showSnackbar({
	            text: 'User' + ' ' + this.users.selected.name + ' ' + 'Tidak memiliki  Country SignUp, isi dulu di Manage User',
	            color: 'red',
	            timeout: 3000
	          })
	        }
	      }

	      // console.log("data",data)

	      // jika yang ada user_setting atau agent_setting
	      if (data.hasOwnProperty('agent_setting')) {
	        var theCurrency = data.agent_setting.currency
	      } else {
	        var theCurrency = data.user_setting.currency
	      }

	      var payload_addToCart = {
	        ship_to: data_ship_to,
	        ship_from: Number(product.country_id),
	        user_id: data.id,
	        shipment_method: 'air',
	        total_cost: calculation.total_goods_value + calculation.total_logistic_all_in,
	        cart_items: [{
	          product_id: product.id,
	          // diganti dari default shipping to nya user
	          currency: 'IDR',
	          cost: calculation.total_goods_value,
	          logistic_cost: calculation.total_logistic_all_in,
	          quantity: calculation.total_quantity,
	          total_cost: calculation.total_goods_value + calculation.total_logistic_all_in,
	          weight: weight,
	          variants: [],
	          unit_name: this.selectedBulkPrice.unit_name
	        }],
	        currency_order: theCurrency,
	        currency_origin: this.currencyOriginProduct

	      }

	      // console.log('this.users',this.users)

	      // jika user tidak memiliki default_shipping_to
	      // // this.users.selected.country_signup

	      // // this.users.selected.user_setting,hasOwnProperty("default_shipping_to")
	      // if (this.users.selected.user_setting,hasOwnProperty("default_shipping_to")) {
	      // 	// code...
	      // } else {

	      // }

	      // // diganti dari default shipping to nya user
	      // if(isEmpty(this.agents.selected)) {
	      // 		this.users.country_catalogs_selected = this.users.current_country_catalogs_selected
	      // } else {
	      // 	this.agents.country_catalogs_selected = this.agents.current_country_catalogs_selected
	      // }

	      forEach(variants_mix, variant => {
	        const data = {
	          attributes: variant.attributes,
	          image: variant.image,
	          logistic_cost: variant.calculation.logistic_all_in_per_pieces,
	          price_per_item: variant.calculation.price_per_item,
	          quantity: Number(variant.calculation.quantity),
	          sku: variant.sku,
	          subtotal: variant.calculation.landed_cost_all_in_per_pieces * Number(variant.calculation.quantity),
	          variation_id: variant.variation_id
	        }
	        payload_addToCart.cart_items[0].variants.push(data)
	      })

	      if (payload_addToCart.cart_items[0].quantity > 0) {
	        if (this.currencyOriginProduct && product.original_currency === this.currencyOriginProduct) {
	          // console.log('payload_addToCart',payload_addToCart)
	          const response = await CreateOrder.addProducts(payload_addToCart)
	          // console.log('onAddProductModalSave response',response)

	          const dataCart = {
	            ...response.data.attributes,
	            cart: true,
	            lengthItem: response.data.attributes.items.length
	          }

	          if (isEmpty(this.agents.selected)) {
	            this.users.selected.cart = dataCart
	          } else {
	            this.agents.selected.cart = dataCart
	          }
	          // add here to
	          // this.users.selected.cart
	          // this.users.selected.cart.items

	          var responseItems = []
	          this.cart_id = response.data.id
	          forEach(response.included.items, items => {
	            const data = {
	              ...items
	            }
	            responseItems.push(data)
	          })

	          this.addProduct.selected = null
	          this.variations.variants = []
	          this.addProduct.open = false
	          this.addProduct.keyword.action = false
	        }
	      }
	      else {
	        quantityZero = true
	      }
	    } else if (data.id == null) {
	      this.showSnackbar({
	        text: 'Please selected User...!!!',
	        color: 'red',
	        timeout: 3000
	      })
	    } else {
	      this.showSnackbar({
	        text: 'Please select Product...!!!',
	        color: 'red',
	        timeout: 3000
	      })
	    }

	  } catch (error) {
	    this.catchHandler(error)
	  }
	  finally {
	    this.selectedBulkPrice = []
	    if (data.id == null || !valid) {
	      this.products.loading = false
	      this.closeLoading()
	    }
	    else if (quantityZero) {
	      this.showSnackbar({
	        text: 'Please add quantity select product...!!!',
	        color: 'red',
	        timeout: 3000
	      })
	      this.closeLoading()
	    }
	    else if (this.currencyOriginProduct && product.original_currency != this.currencyOriginProduct) {
	      this.showSnackbar({
	        text: 'Please add product with ' + this.currencyOriginProduct + ' currency!',
	        color: 'red',
	        timeout: 3000
	      })
	      this.closeLoading()
	    } else {

	      this.setCartToProducts(responseItems)

	      this.showSnackbar({
	        text: 'Add Product Successfully!',
	        color: 'green',
	        timeout: 2500
	      })
	    }
	  }
	}


	//Action perProductVariants
	//Delete Variant
	openDialogDeleteItem(props) {
	  this.dialogDeleteItem.open = true
	  this.dialogDeleteItem.product_name = props.product_name
	  this.dialogDeleteItem.product_id = props.id
	}
	closeDialogDeleteItem() {
	  this.dialogDeleteItem.open = false
	}
	async deleteItem() {
	  try {
	    // sini
	    // cek jika product tinggal 1 maka sekalian hapus cart
	    // deleteAllCartItem
	    // this.products.data.length > 0

	    // if (this.products.data.length === 1) {
	    // 	// code...
	    // 	if(isEmpty(this.agents.selected)) {
	    // 		this.tryDelAll(this.users.selected.cart.id)
	    // 	} else {
	    // 		this.tryDelAll(this.agents.selected.cart.id)
	    // 	}
	    // }
	    // console.log('this.products.data',this.products.data)

	    if (this.products.data.length === 1) {
	      this.dialogDeleteItem.open = true
	      this.showLoading({ text: 'Deleted product...' })
	      this.products.loading = true
	      // code...
	      if (isEmpty(this.agents.selected)) {
	        this.tryDelAll(this.users.selected.cart.id)
	      } else {
	        this.tryDelAll(this.agents.selected.cart.id)
	      }
	      var ResponseItems = []
	      this.setCartToProducts(ResponseItems)

	    } else {
	      this.dialogDeleteItem.open = true
	      this.showLoading({ text: 'Deleted product...' })
	      this.products.loading = true
	      const Response = await CreateOrder.deleteItem(this.dialogDeleteItem.product_id)
	      var ResponseItems = []
	      this.cart_id = Response.data.id
	      forEach(Response.included.items, items => {
	        const data = {
	          ...items
	        }
	        ResponseItems.push(data)
	      })
	      // console.log('ResponseItems',ResponseItems)
	      this.setCartToProducts(ResponseItems)
	    }

	  } catch (error) {
	    this.catchHandler(error)
	  } finally {
	    this.dialogDeleteItem.open = false
	  }
	}
	//GotoLink
	goToSourceUrl(props: any) {
	  const sourceUrl = props.item.original_url
	  if (isEmpty(sourceUrl)) {
	    this.showSnackbar({
	      text: 'Sorry, no source URL for this item',
	      color: 'red',
	      timeout: 2000
	    })
	  } else {
	    this.goTo(this.$router, sourceUrl, '_blank')
	  }
	}


	//Tombol Publish This Order
	checkCart: number = null
	async newCreateOrder() {
	  //Order Date
	  if (!this.orderDate.order_date_date) {
	    this.orderDate.order_date_date = moment(new Date().toLocaleString()).format('YYYY-MM-DD')
	  }

	  if (isEmpty(this.agents.selected)) {
	    this.checkCart = this.users.selected.cart.lengthItem
	  } else {
	    this.checkCart = this.agents.selected.cart.lengthItem
	  }
	  if (isEmpty(this.users.selected)) {
	    this.showSnackbar({
	      text: 'Choose User first !',
	      color: 'info',
	      timeout: 2000
	    })
	  }
	  else if (this.checkCart === 0) {
	    this.showSnackbar({
	      text: 'Cart Item is Empty !',
	      color: 'info',
	      timeout: 2000
	    })
	  }
	  else {
	    try {
	      this.showLoading({ text: 'Loading...' })

	      if (this.agents.selected.length != 0) {
	        this.agents.is_agent_value = true
	      }

	      if (isEmpty(this.contacts.selected) && !isEmpty(this.agents.selected)) {
	        this.contacts.selected.first_name = this.infos_user.first_name
	        this.contacts.selected.last_name = this.infos_user.last_name
	        this.contacts.selected.company = this.infos_user.company
	        this.contacts.selected.address = this.infos_user.address_1
	        this.contacts.selected.subdistrict = this.infos_user.address_2
	        this.contacts.selected.city_name = this.infos_user.city
	        this.contacts.selected.city_id = this.infos_user.city_id
	        this.contacts.selected.province = this.infos_user.state
	        this.contacts.selected.postcode = this.infos_user.postcode
	        this.contacts.selected.country = this.infos_user.country
	        this.contacts.selected.phone = this.infos_user.phone
	        this.contacts.selected.email = this.infos_user.email
	      }

	      if (this.products.data.length > 0) {
	        // if (this.contacts.selected.id != null && this.products.data.length > 0) {

	        let filter_country_id
	        if (isEmpty(this.agents.selected)) {
	          filter_country_id = Number(this.users.selected.user_setting.default_shipping_to)
	        } else {
	          filter_country_id = Number(this.agents.selected.agent_setting.default_shipping_to)
	        }
	        const optsSettings: any = {
	          params: {
	            'filter[country_id][is]': filter_country_id
	          }
	        }
	        const responseSettings = await SettingsService.getSettings(optsSettings)
	        const global_setting_by_key = responseSettings.data.data.find(x => x.attributes.key === 'official_weight_cost')
	        const get_global_setting_logistic_per_unit = Number(global_setting_by_key.attributes.value)

	        const global_setting_currency_by_key = responseSettings.data.data.find(x => x.attributes.key === 'official_weight_currency')
	        const get_global_setting_currency = global_setting_currency_by_key.attributes.value

	        // console.log('this.users',this.users)
	        if (this.agents.is_agent_value === true) {
	          this.users.order_for_email = this.users.selected.email
	        }

	        const payload: any = {
	          user_id: this.users.selected.id,
	          ordered_by_user_id: this.agents.selected.id,
	          logistic_per_unit: get_global_setting_logistic_per_unit,
	          order_for_email: this.users.order_for_email,
	          is_agent: this.agents.is_agent_value,
	          courier_cost: 0,
	          currency: get_global_setting_currency,
	          logistic_cost: 0,
	          cost: 0,
	          total_cbm: this.details.data.total_cbm,
	          total_weight: this.details.weight,

	          save_contact: false,
	          currency_order: this.details.data.currency_order,
	          currency_origin: this.details.data.currency_origin,
	          conversion: '',
	          weight_unit: 'Kg',
	          default_vat: 0,
	          order_type: 'Trading',
	          terms: 'EXW',
	          load_type: 'LCL',
	          order_infos: {},
	          custom_charges: {},

	          total_logistic_charges: this.details.data.total_logistic_charge,
	          total_custom_charges: this.details.data.total_custom_charge,
	          total_goods_value: this.details.data.total_goods_value,
	          grand_total: this.details.data.grand_total,
	          total_duty: this.details.data.total_duty,
	          total_wht: this.details.data.total_wht,
	          total_vat: this.details.data.total_vat,
	          order_date: this.orderDate.order_date_date
	        }

	        for (const dataPerItems of this.products.data) {
	          payload.cost = payload.cost + dataPerItems.cost
	          payload.logistic_cost = payload.logistic_cost + dataPerItems.logistic_cost
	        }

	        const infos: any = {
	          shipping: {
	            first_name: this.contacts.selected.first_name,
	            last_name: this.contacts.selected.last_name,
	            company: this.contacts.selected.company,
	            address_1: this.contacts.selected.address,
	            address_2: this.contacts.selected.subdistrict,
	            city: this.contacts.selected.city_name,
	            city_id: this.contacts.selected.city_id,
	            state: this.contacts.selected.province,
	            postcode: this.contacts.selected.postcode,
	            country: this.contacts.selected.country,
	            phone: this.contacts.selected.phone,
	            total_cbm: null,
	            total_weight: this.details.weight
	          },
	          billing: {
	            first_name: this.contacts.selected.first_name,
	            last_name: this.contacts.selected.last_name,
	            company: this.contacts.selected.company,
	            address_1: this.contacts.selected.address,
	            address_2: this.contacts.selected.subdistrict,
	            city: this.contacts.selected.city_name,
	            city_id: this.contacts.selected.city_id,
	            state: this.contacts.selected.province,
	            postcode: this.contacts.selected.postcode,
	            country: this.contacts.selected.country,
	            phone: this.contacts.selected.phone,
	            email: this.contacts.selected.email
	          }
	        }
	        payload.order_infos = infos

	        // have to get from calculation
	        // console.log('this.calculationResult',this.calculationResult.data.custom_logistic_charges)

	        // console.log('filter',this.calculationResult.data.custom_logistic_charges.filter(x => x.name !== "Estimate Weight Charge (KG)"))

	        var set_custom_charges = this.calculationResult.data.custom_logistic_charges.filter(x => x.name !== 'Estimate Weight Charge (KG)')

	        // var setWeightCharge = this.calculationResult.data.custom_logistic_charges.filter(x => x.name === "Estimate Weight Charge (KG)")
	        // var setCbmCharge = this.responseCalculation.custom_logistic_charges.filter(x => x.name === "Estimate Volume Charge (CBM)")

	        const customCharge: any = {
	          amount: 0,
	          vat_percentage: 0,
	          // details: [{
	          // 	type: "custom-optional",
	          // 	group: '[Estimate] China Domestic Shipping',
	          // 	name: '[Estimate] China Domestic Shipping',
	          // 	sub_total: 0,
	          // 	vat: 0,
	          // 	vat_value: 0,
	          // 	grand_total: 0,
	          // 	is_vat: 0
	          // }]
	          details: []
	        }

	        forEach(set_custom_charges, the_charge => {
	          // console.log('the_charge',the_charge)
	          var payload_custom_charge = {
	            type: 'custom-optional',
	            group: the_charge.name,
	            name: the_charge.name,
	            sub_total: Number(the_charge.price),
	            vat: 0,
	            vat_value: 0,
	            grand_total: Number(the_charge.total),
	            is_vat: 0
	          }

	          customCharge.details.push(payload_custom_charge)
	        })

	        // customCharge.details = set_custom_charges
	        payload.custom_charges = customCharge

	        // console.log('payload',payload)

	        const response = await CreateOrder.createOrder(this.cart_id, payload)
	        // console.log('response',response)
	        var id = response.data.id
	        this.showSnackbar({
	          text: 'Create Order Successfully!',
	          color: 'green',
	          timeout: 2500
	        })

	      }
	      // else if (this.contacts.selected.id == null) {
	      // 	this.showSnackbar({
	      // 		text: "Please Selected Contact of Address Destinations..!!!",
	      // 		color: "red",
	      // 		timeout: 3000
	      // 	})
	      // }
	      else {
	        this.showSnackbar({
	          text: 'Please selected Product..!!!',
	          color: 'red',
	          timeout: 3000
	        })
	      }
	    } catch (error) {
	      this.catchHandler(error)
	    } finally {
	      // if (this.contacts.selected.id != null && this.products.data.length > 0) {
	      if (this.products.data.length > 0) {
	        this.closeLoading()
	        window.location.href = `/orders/${id}/details`
	      } else {
	        this.closeLoading()
	      }
	    }
	  }

	}
	// getImageUrl(images: object[]): any {
	// 	return map(images, "imageUrl")
	// }
	$viewer: any
	inited(viewer: any): void {
	  this.$viewer = viewer
	}
	showImage(props: any): void {
	  if (props.item.imagesViewer[0] !== 'no image') {
	    this.$viewer.show()
	  } else {
	    this.showSnackbar({
	      text: 'Sorry, no image available for this item',
	      color: 'red',
	      timeout: 2000
	    })
	  }
	}

	$viewerVar: any
	initedVar(viewer: any): void {
	  this.$viewerVar = viewer
	}
	showImageVar(props: any): void {
	  // console.log('props',props)
	  // console.log('this.$viewerVar',this.$viewerVar)
	  if (props.item.imagesViewerVariant[0] !== 'no image') {
	    this.$viewerVar.show()
	  } else {
	    this.showSnackbar({
	      text: 'Sorry, no image available for this item',
	      color: 'red',
	      timeout: 2000
	    })
	  }
	}
}